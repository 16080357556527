<template>
  <div
    class="image-cover"
    style="
      overflow-y: hidden;
      overflow-x: hidden;
      background: url(/img/971TutorsImages/categories/amd.jpeg);
      height: 100vh;
    "
  >
    <div class="main-step header-main-step">
      <img width="150" src="img/logo.png" class="logo" alt="" />
    </div>

    <div class="main-step">
      <h3>Email Verification</h3>
      <h6>{{co_fb_auth.currentUser.email}}</h6>
    </div>
    <div class="main-step">
      <!-- <h5 style="margin-top: 10px; margin-bottom: 10px">Step 1</h5> -->
      <button @click="m_sendEmail" class="btn btn-theme">
        Resend verification email
      </button>
    </div>
    <div class="main-step">
      <h5 style="margin-top: 10px; margin-bottom: 10px">
        After verifying your email
      </h5>
      <button @click="m_goToAccount" class="btn btn-theme">
        Proceed to account
      </button>
    </div>
  </div>
</template>

<script>
import { fb_auth } from "@/lib-core/lib-firebase";
export default {
  name: "cmp-verify",
  data() {
    return {
      d_isEmailSent: false,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
      if ( fb_auth && fb_auth.currentUser && !fb_auth.currentUser.emailVerified)
        this.m_sendEmail();
    }, 1000);
  },
  computed: {
    co_fb_auth: function() {
      return fb_auth;
    }
  },
  methods: {
    m_sendEmail() {
      const currentUser = fb_auth.currentUser;
      if (currentUser == null) {
        fb_auth.signOut();
        return;
      }
      currentUser
        .sendEmailVerification()
        .then(() => {
          setTimeout(() => {
            this.m_showDialog(
              "Verification email Sent",
              "Proceed after verifying your email"
            );
          });
        })
        .catch((p_error) => {
          switch (p_error.code) {
            case "auth/too-many-requests":
              this.m_showDialog("Email Sent", "Check your inbox");
              break;
            default:
              this.m_showDialog("Oops", "Check your Internet Connection");
          }
        });
    },
    m_goToAccount() {
      window.location.href = "account";
    },
    m_showDialog(p_title, p_message) {
      let l_payload = {
        title: p_title,
        message: p_message,
        button: {
          yes: "Ok",
        },
      };
      this.$confirm(l_payload);
    },
  },
}; //
</script>

<style>
.main-step {
  border: 1px solid #e9edf3;
  padding: 20px;
  width: 80vw;
  max-width: 500px;
  margin: 0 auto;

  background: #fff;
  text-align: center;
  opacity: 0.95;
}
.header-main-step {
  margin-top: 25vh;
}

@media (max-width: 575px) {
  .header-main-step {
    margin-top: 15vh;
  }
}
</style>